import { Map as hashMap } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { connect } from 'react-redux';

import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import CodeEditorLoader from './CodeEditorLoader';

// Legacy solution tabs key looked like "solutionReadOnly.sql"
const legacyReadOnlyRegex = /ReadOnly/gi;

function isReadOnly(key: any) {
  return legacyReadOnlyRegex.test(key);
}

const mapStateToProps = (key: any) => (state: any) => {
  const exercise = selectors.selectExercise(state);
  const subExercise = selectors.selectCurrentSubExercise(state);
  const canSubmit = selectors.selectCanSubmitCurrentExercise(state);
  const isCoursePrivate = selectors.selectCourse(state).get('private');
  const isEnabledForAIExplainSolution =
    selectors.selectUserIsEnabledForAIExplainSolution(state);
  const isUserAuthStateError = selectors.selectIsUserStatusError(state);
  const isSolution = startsWith(key, 'solution');
  const canExplainSolution =
    isEnabledForAIExplainSolution && !isCoursePrivate && isSolution;

  const fileTab = selectors.selectEditorTabs(state).get(key, hashMap()).toJS();
  const fileTabProps = get(fileTab, 'props', {});
  const resetCode = selectors
    .selectCurrentExOrSubEx(state)
    .get(fileTab.isSolution ? 'solution' : 'sample_code');
  const code = isEmpty(fileTabProps.code) ? resetCode : fileTabProps.code;

  // For dash exercises the run button is removed,
  // because run does not paste submitted code into server.py.
  const canRun = !startsWith(
    exercise.toJS().pre_exercise_code,
    '# dash_exercise',
  );

  const props = {
    ...exercise.toJS(),
    ...subExercise.toJS(),
    ...fileTabProps,
    ...subExercise.get('tabs', hashMap()).toJS()[key],
    code,
    resetCode,
    nbAttempts: selectors.selectNbAttempts(state),
    ckey: key,
    canSubmit,
    disableExecution: isUserAuthStateError,
    canRun,
    isSolution,
    canExplainSolution,
    uiTheme: state.getIn(['settings', 'uiTheme']),
    readOnly: isReadOnly(key) || fileTabProps.readOnly === true,
    shouldFocus: !selectors.selectQueryNoFocus(state),
  };

  props.key = props.id;

  /*
   * This is a hack because Coding Exercise used to send "submission code" to campus
   * (https://github.com/datacamp-engineering/coding-exercise/blob/ab553f4a52a0cc78b3d742f166a0f2a74d33f845/src/state/code.ts#L103)
   * This caused the code "submission code" to be saved and instead of the actual code submitted
   * If that's the case, restore the sample_code
   */
  if (props.code === 'submission code') {
    props.code = resetCode;
  }

  return props;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveCode: (settings: any) => dispatch(actions.saveCode(settings)),
    onSubmitCode: (settings: any) => dispatch(actions.executeCode(settings)),
    updateConsoleCode: (settings: any) =>
      dispatch(actions.updateConsoleCode(settings)),
    onSubmitShortcut: () => dispatch(actions.incrementShortcutUsage()),
    updateAttempts: (nbAttempts: any) =>
      dispatch(actions.updateAttempts({ nbAttempts })),
    onCopySolutionAndSubmit: ({ code, command }: any) => {
      dispatch(actions.copySolution({ code }));
      dispatch(actions.executeCode({ code, command }));
    },
    onRunCode: () => dispatch(actions.runCodeUsage()),
    toggleUITheme: () => dispatch(actions.toggleUITheme()),
    onExplainSolution: (category: string, title: string) => {
      dispatch(
        actions.setCodeExplanation({
          data: { type: 'initial' },
        }),
      );
      dispatch(
        actions.addTab({
          category,
          key: 'codeExplanation',
          title,
        }),
      );
    },
  };
};

export const GenericEditor = (key: any) =>
  connect(mapStateToProps(key), mapDispatchToProps)(CodeEditorLoader);
export const ScriptEditor = connect(
  mapStateToProps('script'),
  mapDispatchToProps,
)(CodeEditorLoader);
export const SolutionEditor = connect(
  mapStateToProps('solution'),
  mapDispatchToProps,
)(CodeEditorLoader);
export const SolutionReadOnlyEditor = connect(
  mapStateToProps('solutionReadOnly'),
  mapDispatchToProps,
)(CodeEditorLoader);

export const MarkdownEditor = (key: any) =>
  connect(
    (state: any) => ({
      ...selectors.selectExercise(state).toJS(),

      ...selectors
        .selectInputMarkdownTabs(state)
        .getIn([key, 'props'], hashMap())
        .toJS(),

      markdown: selectors.selectMarkdown(state).toJS(),
      canSubmit: false,
      canRun: false,
      isMarkdownExercise: true,
      ckey: key,
      uiTheme: state.getIn(['settings', 'uiTheme']),
      shouldFocus: !selectors.selectQueryNoFocus(state),
    }),
    mapDispatchToProps,
  )(CodeEditorLoader);
