/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { Practice } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../../config';
import { useDispatch } from '../../../../interfaces/State';
import * as actions from '../../../../redux/actions';

const PracticeEngagement: React.FC<{
  chapterId: number;
  courseId: number;
  nextExercisePath: string;
  practiceLink: string;
}> = ({ chapterId, courseId, nextExercisePath, practiceLink }) => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();

  const goToNextExercise = useCallback(() => {
    dispatch(actions.nextInternalExercise());
  }, [dispatch]);

  const { host } = window.location;

  const urlRedirect = encodeURIComponent(
    `https://${host}/courses/${nextExercisePath}`,
  );

  useEffect(() => {
    if (window.dataLayer && courseId && chapterId) {
      window.dataLayer.push({
        event: 'heap_event',
        heap_event_name: 'Campus - Signposting For Practice Engagement',
        heap_event_properties: {
          app_id: config.appName,
          event_category: 'practice engagement',
          course_id: courseId,
          chapter_id: chapterId,
        },
      });
    }
  }, [chapterId, courseId]);

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: 500,
      }}
    >
      <div
        css={{
          alignItems: 'center',
          background: tokens.colors.orange,
          borderRadius: tokens.borderRadius.circle,
          display: 'flex',
          flexShrink: 0,
          height: 56,
          justifyContent: 'center',
          width: 56,
          marginBottom: tokens.spacing.medium,
        }}
      >
        <Practice size="xlarge" />
      </div>
      <Heading
        css={{
          color: tokens.colors.white,
        }}
        size="xxlarge"
      >
        {t('Assignment.PracticeEngagement.title')}
      </Heading>
      <Text
        as="div"
        css={{
          marginBottom: tokens.spacing.medium,
          paddingLeft: tokens.spacing.medium,
          paddingRight: tokens.spacing.medium,
          color: tokens.colors.white,
          lineHeight: tokens.lineHeights.relaxed,
          textAlign: 'center',
        }}
      >
        {t('Assignment.PracticeEngagement.subtitle')}
      </Text>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: tokens.spacing.small,
          width: '100%',
          maxWidth: 220,
        }}
      >
        <Button
          variant="cta"
          fullWidth
          as="a"
          href={`${practiceLink}?completionRedirect=${urlRedirect}`}
          data-trackid="campus-end-of-chapter-practice-signpost-practice"
        >
          {t('Practice Quiz')} (+250XP)
        </Button>
        <Button
          css={{
            borderColor: tokens.colors.white60,
            color: tokens.colors.greyLight,
          }}
          variant="regularOutline"
          fullWidth
          onClick={goToNextExercise}
          data-trackid="campus-end-of-chapter-practice-signpost-continue"
        >
          {t('Assignment.PracticeEngagement.gotToNextChapter')}
        </Button>
      </div>
    </div>
  );
};

export default PracticeEngagement;
