export const TEST_PRACTICE_ENGAGEMENT_EXERCISES_LIST: Record<
  number,
  Record<number, { nextExercisePath: string; practiceLink: string }>
> = {
  // Introduction to Python
  735: {
    1851: {
      practiceLink: 'https://practice.datacamp.com/p/3/chapter/1851',
      nextExercisePath:
        'intro-to-python-for-data-science/chapter-3-functions-and-packages?ex=1',
    },
    1852: {
      practiceLink: 'https://practice.datacamp.com/p/3/chapter/1852',
      nextExercisePath: 'intro-to-python-for-data-science/chapter-4-numpy?ex=1',
    },
  },
  // Intermediate Python
  799: {
    1906: {
      practiceLink: 'https://practice.datacamp.com/p/5/chapter/1906',
      nextExercisePath:
        'intermediate-python/logic-control-flow-and-filtering?ex=1',
    },
    1907: {
      practiceLink: 'https://practice.datacamp.com/p/5/chapter/1907',
      nextExercisePath: 'intermediate-python/loops?ex=1',
    },
  },
  // Intermediate SQL
  29303: {
    97077: {
      practiceLink: 'https://practice.datacamp.com/p/588/chapter/97077',
      nextExercisePath: 'intermediate-sql/aggregate-functions-3?ex=1',
    },
    97078: {
      practiceLink: 'https://practice.datacamp.com/p/588/chapter/97078',
      nextExercisePath: 'intermediate-sql/sorting-and-grouping-4?ex=1',
    },
  },
  // Introduction to R
  58: {
    317: {
      practiceLink: 'https://practice.datacamp.com/p/2/chapter/317',
      nextExercisePath: 'free-introduction-to-r/chapter-3-matrices-3?ex=1',
    },
    318: {
      practiceLink: 'https://practice.datacamp.com/p/2/chapter/318',
      nextExercisePath: 'free-introduction-to-r/chapter-4-factors-4?ex=1',
    },
  },
};
