/** @jsx jsx */
import { HTMLContent } from '@datacamp/le-shared-components';
import { Avatar } from '@datacamp/waffles/avatar';
import { Badge } from '@datacamp/waffles/badge';
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { ChevronDown, ChevronUp, Practice } from '@datacamp/waffles/icon';
import { Link } from '@datacamp/waffles/link';
import { Progress } from '@datacamp/waffles/progress';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import max from 'lodash/max';
import min from 'lodash/min';
import type { MutableRefObject } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { scrollTo } from '../../../helpers/domManipulation';
import { useIsMobileScreen } from '../../../hooks/useIsMobileScreen';
import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';

import type { ExerciseOutline } from './ChapterExercises';
import ChapterExercises from './ChapterExercises';

export const TEST_PRACTICE_ENGAGEMENT_EXERCISES_LIST: {
  [courseSlug: string]: { [chapterNumber: number]: { practiceUrl: string } };
} = {
  // Data manipulation with pandas (data-manipulation-with-pandas) (ID: 22066)
  'data-manipulation-with-pandas': {
    1: { practiceUrl: 'https://practice.datacamp.com/p/513/chapter/69739' },
    2: { practiceUrl: 'https://practice.datacamp.com/p/513/chapter/69740' },
    3: { practiceUrl: 'https://practice.datacamp.com/p/513/chapter/69741' },
    4: { practiceUrl: 'https://practice.datacamp.com/p/513/chapter/69742' },
  },
  // Joining data with pandas (joining-data-with-pandas) (ID: 22639)
  'joining-data-with-pandas': {
    1: { practiceUrl: 'https://practice.datacamp.com/p/531/chapter/71805' },
    2: { practiceUrl: 'https://practice.datacamp.com/p/531/chapter/71806' },
    3: { practiceUrl: 'https://practice.datacamp.com/p/531/chapter/71807' },
    4: { practiceUrl: 'https://practice.datacamp.com/p/531/chapter/71808' },
  },
  // Introduction to SQL (introduction-to-sql) (ID: 29302)
  'introduction-to-sql': {
    1: { practiceUrl: 'https://practice.datacamp.com/p/591/chapter/97072' },
    2: { practiceUrl: 'https://practice.datacamp.com/p/591/chapter/97073' },
  },
  // Joining data in SQL (joining-data-in-sql) (ID: 29304)
  'joining-data-in-sql': {
    1: { practiceUrl: 'https://practice.datacamp.com/p/587/chapter/97080' },
    2: { practiceUrl: 'https://practice.datacamp.com/p/587/chapter/97081' },
    3: { practiceUrl: 'https://practice.datacamp.com/p/587/chapter/97082' },
    4: { practiceUrl: 'https://practice.datacamp.com/p/587/chapter/97083' },
  },
};

export type ChapterOutline = {
  description: string;
  exercises?: ExerciseOutline[];
  free_preview?: boolean;
  id: number;
  nb_exercises: number;
  number: number;
  progress?: unknown[];
  slug?: string;
  title: string;
};
type ChapterProps = ChapterOutline & {
  courseRef: number | string;
  currentChapter?: any;
  currentExerciseId?: number;
  currentExercises: ExerciseOutline[];
  currentExercisesProgress?: unknown[];
  disableTabbing?: boolean;
  hideChapterNumber?: boolean;
  onExit: () => void;
  onFirstLinkRendered?: (element: HTMLElement | null) => void;
  onViewDetailExercises: (...args: any[]) => any;
  userChapterProgress?: unknown;
};

const Chapter: React.FC<ChapterProps> = ({
  courseRef,
  currentChapter,
  currentExerciseId,
  currentExercises,
  currentExercisesProgress,
  description,
  disableTabbing,
  exercises,
  free_preview: freePreview,
  hideChapterNumber,
  id,
  nb_exercises: numberOfExercises,
  number: chapterNumber,
  onExit,
  onFirstLinkRendered,
  onViewDetailExercises,
  progress,
  slug,
  title,
  userChapterProgress,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const modal = useSelector(selectors.selectModalSettings);
  const chapterDom = useRef() as MutableRefObject<HTMLLIElement>;
  const { t } = useTranslation(['translation', 'glossary']);
  const isMobileScreen = useIsMobileScreen();

  const chapterRef = slug || id;
  const isCurrentChapter = id === currentChapter.id;
  const relevantExercises = isCurrentChapter ? currentExercises : exercises;
  const relevantExercisesProgress =
    (isCurrentChapter ? currentExercisesProgress : progress) || [];
  const learningMode = useSelector(selectors.selectLearningMode);
  const completedExercises = useMemo(() => {
    return map(
      relevantExercises,
      (ex, index) =>
        // @ts-expect-error Progress is not properly typed
        (ex.user && ex.user.completed && ex.user.completed.completed) ||
        (relevantExercisesProgress[index] &&
          // @ts-expect-error Progress is not properly typed
          relevantExercisesProgress[index].completed),
    );
  }, [relevantExercises, relevantExercisesProgress]);

  const numberOfCompletedExercises = useMemo(() => {
    const completedExs = countBy(completedExercises).true || 0;
    if (isEmpty(userChapterProgress)) {
      return completedExs;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return max([userChapterProgress.nb_completed_exercises, completedExs]);
  }, [completedExercises, userChapterProgress]);

  const percentage = min([
    Math.ceil((numberOfCompletedExercises / numberOfExercises) * 100),
    100,
  ]);

  useEffect(() => {
    setIsExpand(isCurrentChapter);
  }, [isCurrentChapter, modal]);

  useEffect(() => {
    if (isCurrentChapter) {
      // 'Wait' for other DOM content to load/update
      setTimeout(() => {
        const domModal = getDomModal();
        if (domModal == null) {
          return;
        }
        scrollTo(domModal, (chapterDom.current?.offsetTop || 0) - 50, 300);
      }, 0);
    }
  }, [chapterDom, isCurrentChapter]);

  // Bad pattern to access to this way
  const getDomModal = () => {
    return document.querySelector('#course-outline-chapters');
  };

  const onViewDetails = (e: any, isExpanding: any): void => {
    e.preventDefault();
    setIsExpand((prevState) => !prevState);
    onViewDetailExercises(isExpanding, isCurrentChapter, {
      courseRef,
      chapterRef,
    });

    const domModal = getDomModal();
    if (domModal == null) {
      return;
    }
    scrollTo(domModal, (chapterDom.current?.offsetTop || 0) - 60, 300);
  };

  return (
    <li
      data-cy="outline-chapter"
      ref={chapterDom}
      css={{
        border: `${tokens.borderWidth.thin} solid ${tokens.colors.transparentGrey}`,
        borderRadius: tokens.borderRadius.medium,
        marginBottom: tokens.spacing.medium,
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          padding: tokens.spacing.medium,
          paddingBottom: 0,
        }}
      >
        <header
          css={{
            display: 'flex',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              gap: tokens.spacing.small,
            }}
          >
            {!hideChapterNumber && chapterNumber !== undefined && (
              <Avatar
                size="xsmall"
                variant="navy"
                content={chapterNumber.toString()}
                css={{ fontWeight: tokens.fontWeights.bold }}
              />
            )}
            <Heading
              size="large"
              css={{
                margin: 0,
              }}
            >
              {title}
            </Heading>
            {freePreview && (
              <Badge variant="yellow">{t('glossary:free')}</Badge>
            )}
          </div>
          <div
            css={{
              paddingLeft: tokens.spacing.medium,
              width: 200,
            }}
          >
            <Progress
              value={percentage}
              max={100}
              aria-label={t('CourseOutlineChapter.progressLabel')}
            />
          </div>
        </header>
        <div
          css={{
            fontSize: tokens.fontSizes.medium,
            fontFamily: tokens.fontFamilies.sansSerif,
            lineHeight: tokens.lineHeights.relaxed,
          }}
        >
          <HTMLContent html={description} customTag="p" />
        </div>
      </div>
      {isExpand && (
        <ChapterExercises
          {...{
            progress: relevantExercisesProgress,
            exercises: relevantExercises,
            currentExerciseId,
            chapterRef,
            courseRef,
            onExit,
            onFirstLinkRendered,
            learningMode,
            disableTabbing,
          }}
        />
      )}
      <div
        css={{
          backgroundColor: tokens.colors.greySubtle,
          padding: tokens.spacing.small,
          display: 'flex',
          flexDirection: 'row',
          placeContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          size="small"
          variant="plain"
          iconRight={isExpand ? <ChevronUp /> : <ChevronDown />}
          tabIndex={disableTabbing ? -1 : 1}
          onClick={(e: any) => onViewDetails(e, !isExpand)}
          data-cy={
            isExpand ? 'outline-reduce-chapter' : 'outline-expand-chapter'
          }
        >
          {isExpand
            ? t('CourseOutlineChapter.expandedButton')
            : t('CourseOutlineChapter.collapsedButton')}
        </Button>
        {!isMobileScreen &&
          percentage === 100 &&
          TEST_PRACTICE_ENGAGEMENT_EXERCISES_LIST[courseRef]?.[chapterNumber]
            ?.practiceUrl && (
            <Button
              as={Link}
              data-testid="campus-outline-chapter-practice"
              data-trackid={'campus-outline-chapter-practice'}
              href={`${
                TEST_PRACTICE_ENGAGEMENT_EXERCISES_LIST[courseRef][
                  chapterNumber
                ].practiceUrl
              }?completionRedirect=${encodeURIComponent(window.location.href)}`}
              iconLeft={
                <Practice
                  css={{
                    [`@media (min-width: 368px)`]: { display: 'inline' },
                    display: 'none',
                  }}
                />
              }
              variant={'secondary'}
            >
              {t(`CourseOutlineChapter.practiceChapter`)}
            </Button>
          )}
      </div>
    </li>
  );
};

export default Chapter;
